import 'intersection-observer';

const initIntersectionObserver = () => {
    var observer;
    const ioConfig = {
        threshold: [0.2, 0.3, 0.5, 0.9]
    }

    var ioCallback = (entries) => {
        entries.forEach((entry) => {
            
            if (entry.target.classList.contains('CaseInformation')) {
                if (entry.intersectionRatio >= 0.9) {
                    toggleObserverItemState(entry, observer)
                }
            } else if (entry.target.classList.contains('CaseImage') || (entry.target.classList.contains('MobileLinks'))) {
                if (entry.intersectionRatio >= 0.2) {
                    toggleObserverItemState(entry, observer)
                }
            } else {
                if (entry.intersectionRatio >= 0.5) {
                    toggleObserverItemState(entry, observer)
                }
            }
        })
    }

    observer = new IntersectionObserver(ioCallback, ioConfig);
    return observer
}

const toggleObserverItemState = (entry, observer) => {
    entry.target.classList.add('animateIn');
    observer.unobserve(entry.target);
}

const startHomepageIntersectionObserver = () => {
    const observeItems = document.querySelectorAll('.IOitem');
    const homepageObserver = initIntersectionObserver();

    observeItems.forEach((item) => {
        homepageObserver.observe(item);
    })
}

export { startHomepageIntersectionObserver };