import Barba from 'barba.js';
import { startHomepageIntersectionObserver } from '../homepage/homepageScroll.js'
import { willLoadExplorationSlider, willAnimateFoldIn, startIntersectionObservers } from '../foreignrap/foreignrap.js'

document.addEventListener("DOMContentLoaded", function() {
    Barba.Pjax.init();
    Barba.Prefetch.init();
})

function ViewManager(view) {
    var foreignrapView = Barba.BaseView.extend({
        namespace: 'foreignrap',
        onEnterCompleted: function() {
            // willLoadExplorationSlider();
            // willAnimateFoldIn();
            // startIntersectionObservers();
        },
        onLeave: function() {
            // destroy intersection observer
        }
    });
    
    var homepageView = Barba.BaseView.extend({
        namespace: 'homepage',
        onEnterCompleted: function() {
            startHomepageIntersectionObserver();
        },
        onLeave: function() {
            // destroy intersection observers
        }
    })

    switch(view) {
        case 'homepage':
            homepageView.init();
            break;
        case 'foreignrap':
            foreignrapView.init();
            break;
        default:
            console.log('no view specified');
    }
}

// Barba.Dispatcher.on('linkClicked', function() {
//     ViewManager();
// });

Barba.Dispatcher.on('newPageReady', function(page) {
    switch(page.namespace){
        case 'homepage':
            ViewManager('homepage');
            break;
        case 'foreignrap':
            ViewManager('foreignrap');
            console.log('foreignrap');
        default:
            console.log('default');
    }
})